import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Layout from "../../../components/Layout"
import AppStoreLinks from "../../../components/AppStoreLinks"
import Seo from "../../../components/Seo"
import ColouringPages from "../../../components/monstapals/ColouringPages"
import StickerPacks from "../../../components/monstapals/StickerPacks"
import Story from "../../../components/monstapals/Story"

const Comments = [
  {
    title: "Great Game we need sequels with continents.",
    text: "I wouldn’t mind see a sequel with Asia…Africa…South America…North America…Europe…Indonesia…the Arctic and Antarctic. I wouldn’t mind one we’re you could combine dinosaurs or prehistoric animals In general I love this game and it’s so cute and addictive",
    user: "Bubegirl",
    region: "US",
    rating: 5
  },
  {
    title: "More animals",
    text: "You need more places to explore and more animals",
    user: "Spackleboy",
    region: "US",
    rating: 5
  },
  {
    title: "Molto carina!",
    text: "Davvero bella l’idea di mixare insieme degli animali per vedere cosa ne viene fuori. Però ce ne vorrebbero molti di più e soprattutto più comuni. Perché non utilizzare gli animali della fattoria o della giungla? Io li prendere anche se fossero pacchetti a pagamento. Altra cosa: l’astronave è davvero difficile da comandare per un bimbo visto che bisogna mandare a sx per farla andare a dx e viceversa. Possibile non di possa trovare un metodo più semplice? Comunque bravi il gioco è molto originale.",
    user: "Steb73",
    region: "IT",
    rating: 4
  }
];

const MonstapalsHeader = () => {
  return (<>
    <aside className="flex flex-col items-center justify-center max-w-screen-lg mx-4 mb-10 lg:mx-auto">
      <div className="shadow-2xl rounded-3xl">
        <StaticImage
          imgClassName="rounded-3xl"
          height={128}
          width={128}
          src="../../../images/icon-monstapals.png"
          alt="App Icon"
          placeholder="dominantColor" />
      </div>
      <StaticImage
        className="mt-8"
        width={400}
        src="../../../images/monstapals-title.svg"
        alt="Monstapals"
        placeholder="dominantColor" />
    </aside>
  </>);
};

const MonstapalsPage = () => {

  return (
    <Layout>
      <Seo
        title="Monstapals"
        description="A fun filled game that teaches you about Australia fauna, available on iOS, Android and macOS"
      />

      <MonstapalsHeader />

      <div className="max-w-screen-lg mx-4 my-10 y-6 lg:mx-auto">
        <div className="flex flex-col justify-between mt-10 lg:flex-row">
          <section className="mb-8 lg:pb-0 lg:w-1/3 lg:mr-10">
            <h3 className="mb-4 text-2xl font-semibold text-anomaly-red">45 pals</h3>
            <p className="text-gray-500">
              There are 45 different Monstapals to create with each having its own distinct look and sound so make sure you find them all.
              Once the Monstapals have been unleashed in their new home you can learn fun facts about each of your creations.
            </p>
          </section>
          <section className="mb-8 lg:pb-0 lg:w-1/3 lg:mr-10">
            <h3 className="mb-4 text-2xl font-semibold text-anomaly-red">Education</h3>
            <p className="text-gray-500">
              At the heart of our project was the aim to educate the user about Australian flora and fauna. An engaging experience keeps the user curious while immersing them in a fantasy world.
            </p>
          </section>
          <section className="mb-8 lb:pb-0 lg:w-1/3">
            <h3 className="mb-4 text-2xl font-semibold text-anomaly-red">4.6 stars</h3>
            <p className="text-gray-500">
              We had a rough start with supporting the enormous amount of artwork, however once we overcame this,  all we hear is praise from around the world.
            </p>
          </section>
        </div>
      </div>

      <StaticImage
        src="../../../images/monstapals-lab.png"
        placeholder="dominantColor"
        alt="Monstapals lab screenshot" />

      <h3 className="my-10 text-3xl font-semibold text-center text-anomaly-red">Features</h3>
      <div className="max-w-screen-lg mx-4 my-10 y-6 lg:mx-auto">
        <div className="flex flex-col justify-between mt-10 lg:flex-row">
          <section className="mb-8 lg:pb-0 lg:w-1/3 lg:mr-10">
            <h3 className="mb-4 text-2xl font-semibold text-anomaly-red">Game play</h3>
            <p className="text-gray-500">
              The game play is designed to be very simple to ensure the player focuses on the key concepts,
              the engaging artwork and environment presented by the game. We’ve placed some historical space moments
              in the art including the Tesla that Space X flew into space.
            </p>
          </section>
          <section className="mb-8 lg:pb-0 lg:w-1/3 lg:mr-10">
            <h3 className="mb-4 text-2xl font-semibold text-anomaly-red">The spacehship</h3>
            <p className="text-gray-500">
              The spaceship is the key control of the game, to enable moving around the world using the navigation unit.
            </p>
          </section>
          <section className="mb-8 lb:pb-0 lg:w-1/3">
            <h3 className="mb-4 text-2xl font-semibold text-anomaly-red">Child friendly</h3>
            <p className="text-gray-500">
              Monstapals was built on our ethos of a fair price for all products.
              The app contains no tracking, advertising or in-app purchases, making it a  a safe parent-approved purchase.
              It does not use the camera, your location or any other data that can be linked to the user.
            </p>
          </section>
        </div>
      </div>

      <div className="grid max-w-screen-lg grid-cols-1 gap-4 my-20 text-sm text-gray-600 bg-white lg:grid-cols-3 y-6 lg:mx-auto">
        {Comments.map((comment) => {
          return (<div className="flex flex-col justify-between p-6 rounded-lg lg:shadow-2xl">
            <div>
              <div className="mb-4 text-yellow-300">
                {[...Array(comment.rating)].map(() => {
                  return (<FontAwesomeIcon size="2x" className="mr-1" icon={faStar} />);
                })}
              </div>
              <p className="mb-4 font-bold uppercase">{comment.title}</p>
              <p>{comment.text}</p>
            </div>
            <div className="mt-4 text-gray-600">
              <p className="font-bold">{comment.user}</p>
            </div>
          </div>);
        })}
      </div>

      <AppStoreLinks
        iOSStoreLink="https://itunes.apple.com/us/app/monstapals/id1313615216?mt=8&at=1l3v8JX"
        googlePlayStoreLink="https://play.google.com/store/apps/details?id=au.net.anomaly.Chimera"
        macOSStoreLink="https://apps.apple.com/au/app/monstapals/id1451766323?mt=12" />

      <StickerPacks />

      <Story />

      <ColouringPages />
    </Layout>
  );
};
export default MonstapalsPage
