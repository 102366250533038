import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const ColouringPages = () => {
  return (<>
    <h3 className="text-3xl font-semibold text-center my-14 text-anomaly-red">Monstapals colouring in pages</h3>
    <section className="grid grid-cols-1 gap-4 mx-auto mb-12 text-center lg:grid-cols-2 lg:max-w-screen-sm">
      <div className="flex flex-col items-center justify-center">
        <a className="shadow-2xl rounded-3xl"
          href="/downloads/Monstapals-Colouring-1.jpg">
          <StaticImage
            height={350}
            width={248}
            src="../../images/monstapals-colouring-1.jpg"
            alt="Couloring in Page 1"
            placeholder="dominantColor" />
        </a>
      </div>
      <div className="flex flex-col items-center justify-center">
        <a className="shadow-2xl rounded-3xl"
          href="/downloads/Monstapals-Colouring-2.jpg">
          <StaticImage
            height={350}
            width={248}
            src="../../images/monstapals-colouring-2.jpg"
            alt="Couloring in Page 1"
            placeholder="dominantColor" />
        </a>
      </div>
    </section>
    <section className="grid grid-cols-1 gap-4 mx-auto text-center lg:grid-cols-3 lg:max-w-screen-lg">
      <div className="flex flex-col items-center justify-center">
        <a className="shadow-2xl rounded-3xl"
          href="/downloads/Monstapals-Halloween-Colouring-1.jpg">
          <StaticImage
            height={350}
            width={248}
            src="../../images/monstapals-halloween-colouring-1.jpg"
            alt="Couloring in Page 1"
            placeholder="dominantColor" />
        </a>
      </div>
      <div className="flex flex-col items-center justify-center">
        <a className="shadow-2xl rounded-3xl"
          href="/downloads/Monstapals-Halloween-Colouring-2.jpg">
          <StaticImage
            height={350}
            width={248}
            src="../../images/monstapals-halloween-colouring-2.jpg"
            alt="Couloring in Page 1"
            placeholder="dominantColor" />
        </a>
      </div>
      <div className="flex flex-col items-center justify-center">
        <a className="shadow-2xl rounded-3xl"
          href="/downloads/Monstapals-Halloween-Colouring-3.jpg">
          <StaticImage
            height={350}
            width={248}
            src="../../images/monstapals-halloween-colouring-3.jpg"
            alt="Couloring in Page 1"
            placeholder="dominantColor" />
        </a>
      </div>
    </section>
  </>);
};

export default ColouringPages;