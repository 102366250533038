import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const StickerPacks = () => {
  return (<>
    <h3 className="mt-10 text-3xl font-semibold text-center text-anomaly-red">Sticker packs</h3>
    <p className="mx-4 my-8 text-center text-gray-600 lg:mx-auto">
      We love the artwork so much that we decided make these little goodies.<br />
      Click the icons and grab the iMessage sticker packs!
    </p>

    <section className="grid grid-cols-1 gap-2 mx-4 text-center lg:mx-auto lg:grid-cols-3 lg:max-w-screen-md text-anomaly-red">
      <div className="flex flex-col items-center justify-center">
        <h3 className="my-2 text-2xl font-semibold">Game</h3>
        <a className="shadow-2xl rounded-3xl"
          href="https://itunes.apple.com/us/app/monstapals-stickers/id1411196357?mt=8">
          <StaticImage
            imgClassName="rounded-3xl"
            height={128}
            width={128}
            src="../../images/icon-monstapals-stickers.png"
            alt="Sticker Game"
            placeholder="dominantColor" />
        </a>
      </div>
      <div className="flex flex-col items-center justify-center">
        <h3 className="my-2 text-2xl font-semibold">Halloween</h3>
        <a className="shadow-2xl rounded-3xl"
          href="https://itunes.apple.com/us/app/monstapals-stickers-halloween/id1437588677?ls=1&mt=8">
          <StaticImage
            imgClassName="rounded-3xl"
            height={128}
            width={128}
            src="../../images/icon-monstapals-halloween-stickers.png"
            alt="Sticker Halloween"
            placeholder="dominantColor" />
        </a>
      </div>
      <div className="flex flex-col items-center justify-center">
        <h3 className="my-2 text-2xl font-semibold">Christmas</h3>
        <a className="shadow-2xl rounded-3xl"
          href="https://apps.apple.com/us/app/monstapals-stickers-christmas/id1444716236">
          <StaticImage
            imgClassName="rounded-3xl"
            height={128}
            width={128}
            alt="Sticker Christmas"
            src="../../images/icon-monstapals-christmas-stickers.png"
            placeholder="dominantColor" />
        </a>
      </div>
    </section>

  </>);
};

export default StickerPacks;
