import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Story = () => {
  return (<>
    <h3 className="text-3xl font-semibold text-center mt-14 text-anomaly-red">What's the story?</h3>
    <p className="mx-4 my-8 text-center text-gray-600 lg:mx-auto">
      Watch our cut scene that tells you about how it all started!<br />
      We have more videos on our YouTube channel.
    </p>
    <iframe
      className="hidden mx-auto border-8 border-gray-500 shadow-2xl lg:block"
      width={1024}
      height={600}
      src="https://www.youtube-nocookie.com/embed/F_-qF875RnE"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    </iframe>
    <div className="flex items-center justify-center w-full lg:hidden mx-auth">
      <a href="https://youtu.be/F_-qF875RnE">
        <StaticImage
          width={200}
          className="mx-auto"
          alt="Play on Youtube"
          src="../../images/logo-youtube.png"
          placeholder="dominantColor" />
      </a>
    </div>

  </>);
};

export default Story;